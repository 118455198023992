import React from "react"
import CookieConsent from 'react-cookie-consent';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import './layout.scss'

export default function Layout({ children }) {

    const data = useStaticQuery(graphql`
        query HeaderQuery {
            file(relativePath: { eq: "jincode-logo-70h.png" }) {
                childImageSharp {
                    fixed(width: 213) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    console.log(data);

    return (
        <div className="container">
            <header>
                <h1>
                    <a href=""><Img fixed={data.file.childImageSharp.fixed} alt="Jincode" /></a>
                </h1>
            </header>
            <div className="content">{children}</div>
            <footer>
                &copy; Copyright Jincode Pty Ltd 2020. ABN 34 625 558 965
            </footer>
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-analytics">
                This site uses cookies to help us understand user behaviour and help us to provide a better user experience.
            </CookieConsent>
        </div>
    )
}
